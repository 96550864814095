 export const EventData = [
  {
     id:1,
     title:'Molecular Drug Discovery Conference',
     date:'Jun 14, 2022',
     time:'12:00 am - 2:30 pm',
     city:'New York',
     img:"/assets/img/course/course-1.jpg",
     category:'Molecular Biology',
     teacherImg: '/assets/img/course/teacher/teacher-1.jpg',
     teacherName: 'Jim Séchen',
     desc:"He legged it up the kyver have it mush super me old mucker cheeky naff that are you taking the piss, blow off down the pub bite your arm off the wireless boot cor blimey guvnor happy days bender what a load of rubbish, say pardon me horse play spiffing Why car boot gosh bubble and squeak. Cheers Richard bugger show off show off pick your nose and blow off get stuffed mate chancer in my flat loo, bevvy amongst hunky-dory bender bubble and squeak me old mucker vagabond, barmy spend a penny chimney pot young delinquent bum bag the bee's knees chap, gosh nice one knees up the wireless Charles such a fibber. Mush barmy bleeding Jeffrey pardon me barney grub loo cup of tea bubble and squeak bugger all mate say, I bloke matie boy tickety-boo give us a bell up the duff bugger lurgy wind up I don't want no agro.",
     price:79.99,
     old_price:129.99,
  },
  {
     id:2,
     title:'World education day conference',
     date:'April 10, 2022',
     time:'9:00 am - 5:00 pm',
     city:'Mindahan',
     img:"/assets/img/course/course-2.jpg",
     category:'Material Science',
     teacherImg: '/assets/img/course/teacher/teacher-2.jpg',
     teacherName: 'Barry Tone',
     desc:"He legged it up the kyver have it mush super me old mucker cheeky naff that are you taking the piss, blow off down the pub bite your arm off the wireless boot cor blimey guvnor happy days bender what a load of rubbish, say pardon me horse play spiffing Why car boot gosh bubble and squeak. Cheers Richard bugger show off show off pick your nose and blow off get stuffed mate chancer in my flat loo, bevvy amongst hunky-dory bender bubble and squeak me old mucker vagabond, barmy spend a penny chimney pot young delinquent bum bag the bee's knees chap, gosh nice one knees up the wireless Charles such a fibber. Mush barmy bleeding Jeffrey pardon me barney grub loo cup of tea bubble and squeak bugger all mate say, I bloke matie boy tickety-boo give us a bell up the duff bugger lurgy wind up I don't want no agro.",
     price:89.99,
     old_price:139.99,
     color: 'orange',
  },
  {
     id:3,
     title:'Foundations of global health',
     date:'July 16, 2022',
     time:'10:30 am - 1:30 pm',
     city:'Weedpatch',
     img:"/assets/img/course/course-3.jpg",
     category:'Drug Discovery',
     teacherImg: '/assets/img/course/teacher/teacher-3.jpg',
     teacherName: 'Eleanor Fant',
     desc:"He legged it up the kyver have it mush super me old mucker cheeky naff that are you taking the piss, blow off down the pub bite your arm off the wireless boot cor blimey guvnor happy days bender what a load of rubbish, say pardon me horse play spiffing Why car boot gosh bubble and squeak. Cheers Richard bugger show off show off pick your nose and blow off get stuffed mate chancer in my flat loo, bevvy amongst hunky-dory bender bubble and squeak me old mucker vagabond, barmy spend a penny chimney pot young delinquent bum bag the bee's knees chap, gosh nice one knees up the wireless Charles such a fibber. Mush barmy bleeding Jeffrey pardon me barney grub loo cup of tea bubble and squeak bugger all mate say, I bloke matie boy tickety-boo give us a bell up the duff bugger lurgy wind up I don't want no agro.",
     price:99.99,
     old_price:149.99,
     color: 'blue',
  },
  {
     id:4,
     title:'Business creativity workshops',
     date:'March 24, 2022',
     time:'10:30 am - 12:00 pm',
     city:'Lnland',
     img:"/assets/img/course/course-4.jpg",
     category:'Marketing',
     teacherImg: '/assets/img/course/teacher/teacher-4.jpg',
     teacherName: 'Brian Cumin',
     desc:"He legged it up the kyver have it mush super me old mucker cheeky naff that are you taking the piss, blow off down the pub bite your arm off the wireless boot cor blimey guvnor happy days bender what a load of rubbish, say pardon me horse play spiffing Why car boot gosh bubble and squeak. Cheers Richard bugger show off show off pick your nose and blow off get stuffed mate chancer in my flat loo, bevvy amongst hunky-dory bender bubble and squeak me old mucker vagabond, barmy spend a penny chimney pot young delinquent bum bag the bee's knees chap, gosh nice one knees up the wireless Charles such a fibber. Mush barmy bleeding Jeffrey pardon me barney grub loo cup of tea bubble and squeak bugger all mate say, I bloke matie boy tickety-boo give us a bell up the duff bugger lurgy wind up I don't want no agro.",
     price:109.99,
     old_price:159.99,
     color: 'pink',
  },
]