export const blog_data = [
  {
    id: 1,
    blogImg: '/assets/img/blog/blog-1.jpg',
    title: 'The Challenge 3-D Cell Culture Techniques',
    author: '/assets/img/blog/author/author-1.jpg',
    authorName: 'Clevaforce',
    date: 'April 02, 2022',
    category: 'Cell Biology',
    tags:['Article','Cell Culture']
  },
  {
    id: 2,
    blogImg: '/assets/img/blog/blog-2.jpg',
    title: 'Latest trends Contract Research ',
    author: '/assets/img/blog/author/author-2.jpg',
    authorName: 'Clevaforce',
    date: 'July 02, 2022',
    category: 'Contract Services',
    color: 'purple',
    tags:['Article','Services','Chemistry']
  },
  {
    id: 3,
    blogImg: '/assets/img/blog/blog-3.jpg',
    title: 'Evolution of Novel Therapeutics Discovery Techniques',
    author: '/assets/img/blog/author/author-3.jpg',
    authorName: 'Clevaforce',
    date: 'March 05, 2022',
    category: 'Drug Discovery',
    color: 'pink',
    tags:['Machine Learning','Discovery']
  },
  {
    id: 4,
    blogImg: '/assets/img/blog/blog-4.jpg',
    title: 'Green Chemistry: What to Expect in the Next Decade',
    author: '/assets/img/course/teacher/teacher-1.jpg',
    authorName: 'Clevaforce',
    date: 'March 7, 2022',
    category: 'Chemistry',
    color: 'sky-blue',
    tags:['Article','Sustainability']
  },
  {
    id: 5,
    blogImg: '/assets/img/blog/blog-5.jpg',
    title: 'Next Generation Protein-Based Therapeutics',
    author: '/assets/img/course/teacher/teacher-2.jpg',
    authorName: 'Clevaforce',
    date: 'April 09, 2022',
    category: 'Drug Discovery',
    color: 'green',
    tags:['Videos']
  },
  {
    id: 6,
    blogImg: '/assets/img/blog/blog-6.jpg',
    title: 'A Review of FDA Decisions on Advanced Therapeutics ',
    author: '/assets/img/course/teacher/teacher-3.jpg',
    authorName: 'Clevaforce',
    date: 'Jun 09, 2022',
    category: 'Regulatory',
    color: 'blue',
    tags:['Regulations','Gene Therapy']
  },
]