const menu_data = [
  // {
  //   id:1,
  //   title:'Home',
  //   link:'/',
  //   submenu:[
  //     {
  //       id:1,
  //       title:'Home Style 1',
  //       link:'/'
  //     },
  //     {
  //       id:2,
  //       title:'Home Style 2',
  //       link:'/home-two'
  //     },
  //     {
  //       id:3,
  //       title:'Home Style 3',
  //       link:'/home-three'
  //     },
  //   ]
  // },
  {
    id:1,
    title:'About',
    link:'/about',
  },
  {
    id:2,
    title:'Courses',
    link:'/courses',
    submenu:[
      {
        id:1,
        title:'Courses',
        link:'/courses',
      },
      {
        id:2,
        title:'Course List',
        link:'/course-list'
      },
      // {
      //   id:3,
      //   title:'Course sidebar',
      //   link:'/course-sidebar'
      // },
      // {
      //   id:4,
      //   title:'Course Details',
      //   link:'/course-details'
      // },
    ]
  },
  {
    id:3,
    title:'Blog',
    link:'/blog',
    submenu:[
      {
        id:1,
        title:'Blog',
        link:'/blog',
      },
      {
        id:2,
        title:'Blog Details',
        link:'/blog-details',
      },
    ]
  },
  {
    id:4,
    title:'Events',
    link:'/event-details',
  },
  

  // {
  //   id:4,
  //   title:'Pages',
  //   link:'/about',
  //   submenu:[
  //     {
  //       id:1,
  //       title:'About',
  //       link:'/about',
  //     },
  //     {
  //       id:2,
  //       title:'Instructor',
  //       link:'/instructor',
  //     },
  //     {
  //       id:3,
  //       title:'Instructor Details',
  //       link:'/instructor-details',
  //     },
  //     {
  //       id:4,
  //       title:'Event Details',
  //       link:'/event-details',
  //     },
  //     {
  //       id:5,
  //       title:'My Cart',
  //       link:'/cart',
  //     },
  //     {
  //       id:6,
  //       title:'My Wishlist',
  //       link:'/wishlist',
  //     },
  //     {
  //       id:7,
  //       title:'checkout',
  //       link:'/checkout',
  //     },
  //     {
  //       id:8,
  //       title:'Sign In',
  //       link:'/sign-in',
  //     },
  //     {
  //       id:9,
  //       title:'Sign Up',
  //       link:'/sign-up',
  //     },
  //     {
  //       id:10,
  //       title:'Error',
  //       link:'/error',
  //     },
  //   ]
  // },

  // {
  //   id:5,
  //   title:'Contact',
  //   link:'/contact'
  // },

]

export default menu_data;