export const banner_one = [
  {
    bg_img:'/assets/img/banner/banner-bg-1.jpg',
    tag:'Free',
    title:<>Germany Foundation <br/> Document</>,
    btn_text:'View Contents',
    img:'/assets/img/banner/banner-img-1.png',
  },
  {
    bg_img:'/assets/img/banner/banner-bg-2.jpg',
    tag:'new',
    title:<>Online Contents <br/>From Clevaforce</>,
    btn_text:'View Contents',
    img:'/assets/img/banner/banner-img-2.png',
  },
]