export const instructor = [
  {
    id: 1,
    img: '/assets/img/teacher/teacger-1.jpg',
    name: 'Jim Séchen',
    title: 'Art & Designer',
    social_links: ['social_facebook', 'social_twitter', 'social_vimeo'],
    short_bio:"Only a quid me old mucker squiffy tomfoolery grub cheers ruddy cor blimey guvnor in my flat, up the duff Eaton car boot up the kyver pardon you A bit of how's your father David skive off sloshed, don't get shirty with me chip shop vagabond crikey bugger Queen's English chap. Matie boy nancy boy bite your arm off up the kyver old no biggie fantastic boot, David have it show off show off pick your nose and blow off lost the plot porkies bits and bobs only a quid bugger all mate, absolutely bladdered bamboozled it's your round don't get shirty with me down the pub well."
  },
  {
    id: 2,
    img: '/assets/img/teacher/teacher-2.jpg',
    name: 'Barry Tone',
    title: 'Discovery Scientist',
    social_links: ['social_facebook', 'social_twitter', 'social_vimeo'],
    short_bio:"Only a quid me old mucker squiffy tomfoolery grub cheers ruddy cor blimey guvnor in my flat, up the duff Eaton car boot up the kyver pardon you A bit of how's your father David skive off sloshed, don't get shirty with me chip shop vagabond crikey bugger Queen's English chap. Matie boy nancy boy bite your arm off up the kyver old no biggie fantastic boot, David have it show off show off pick your nose and blow off lost the plot porkies bits and bobs only a quid bugger all mate, absolutely bladdered bamboozled it's your round don't get shirty with me down the pub well."
  },
  {
    id: 3,
    img: '/assets/img/teacher/teacher-3.jpg',
    name: 'Samuel Serif',
    title: 'Material Science',
    social_links: ['social_facebook', 'social_twitter', 'social_vimeo'],
    short_bio:"Only a quid me old mucker squiffy tomfoolery grub cheers ruddy cor blimey guvnor in my flat, up the duff Eaton car boot up the kyver pardon you A bit of how's your father David skive off sloshed, don't get shirty with me chip shop vagabond crikey bugger Queen's English chap. Matie boy nancy boy bite your arm off up the kyver old no biggie fantastic boot, David have it show off show off pick your nose and blow off lost the plot porkies bits and bobs only a quid bugger all mate, absolutely bladdered bamboozled it's your round don't get shirty with me down the pub well."
  },
  {
    id: 4,
    img: '/assets/img/teacher/teacher-5.jpg',
    name: 'Elon Gated',
    title: 'Material Scientist',
    social_links: ['social_facebook', 'social_twitter', 'social_vimeo'],
    short_bio:"Only a quid me old mucker squiffy tomfoolery grub cheers ruddy cor blimey guvnor in my flat, up the duff Eaton car boot up the kyver pardon you A bit of how's your father David skive off sloshed, don't get shirty with me chip shop vagabond crikey bugger Queen's English chap. Matie boy nancy boy bite your arm off up the kyver old no biggie fantastic boot, David have it show off show off pick your nose and blow off lost the plot porkies bits and bobs only a quid bugger all mate, absolutely bladdered bamboozled it's your round don't get shirty with me down the pub well."
  },
  {
    id: 5,
    img: '/assets/img/teacher/teacher-4.jpg',
    name: 'Eleanor Fant',
    title: 'Marketing',
    social_links: ['social_facebook', 'social_twitter', 'social_vimeo'],
    short_bio:"Only a quid me old mucker squiffy tomfoolery grub cheers ruddy cor blimey guvnor in my flat, up the duff Eaton car boot up the kyver pardon you A bit of how's your father David skive off sloshed, don't get shirty with me chip shop vagabond crikey bugger Queen's English chap. Matie boy nancy boy bite your arm off up the kyver old no biggie fantastic boot, David have it show off show off pick your nose and blow off lost the plot porkies bits and bobs only a quid bugger all mate, absolutely bladdered bamboozled it's your round don't get shirty with me down the pub well."
  },
  {
    id: 6,
    img: '/assets/img/teacher/teacher-6.jpg',
    name: 'Brian Cumin',
    title: 'Bioinformatician',
    social_links: ['social_facebook', 'social_twitter', 'social_vimeo'],
    short_bio:"Only a quid me old mucker squiffy tomfoolery grub cheers ruddy cor blimey guvnor in my flat, up the duff Eaton car boot up the kyver pardon you A bit of how's your father David skive off sloshed, don't get shirty with me chip shop vagabond crikey bugger Queen's English chap. Matie boy nancy boy bite your arm off up the kyver old no biggie fantastic boot, David have it show off show off pick your nose and blow off lost the plot porkies bits and bobs only a quid bugger all mate, absolutely bladdered bamboozled it's your round don't get shirty with me down the pub well."
  },
]

export default instructor;