import { Link } from "react-router-dom";
import { Chat, OnlineCourse, Research, Support } from "../../svg";

const service_data = [
  {
    bg:'blue-bg-4',
    icon:<OnlineCourse/>,
    title:<>Over 4,000 <br /> Contents</>,
    subtitle:'Provided by experts in the health and life science industry'
  },
  {
    bg:'pink-bg',
    icon:<Support/>,
    title:<>Job placement<br /> & Recruitment</>,
    subtitle:'Help companies identify quality talents, and match talents to companies '
  },
  {
    bg:'purple-bg',
    icon:<Chat/>,
    title:<>Communities,<br /> Groups and Forums</>,
    subtitle:'Join forums to accelerate your learning in any bioscience topics'
  },
  {
    bg:'green-bg',
    icon:<Research/>,
    title:<>New Product<br />Launch Support</>,
    subtitle:'Support product understanding, use, training etc.'
  },
]

const Services = () => {
  return (
    <>
      <section className="services__area pt-115 pb-40">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3">
              <div className="section__title-wrapper section-padding mb-60 text-center">
                <h2 className="section__title">Contents from <span className="yellow-bg">experts <img src="/assets/img/shape/yellow-bg-2.png" alt="" /></span> across life science</h2>
                <p>A variety of contents all in one place to achieve the diverse needs of life science companies and talents.</p>
              </div>
            </div>
          </div>
          <div className="row">
            {service_data.map((item,index) => (
            <div key={index} className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className={`services__item ${item.bg} mb-30`}>
                <div className="services__icon">
                  {item.icon}
                </div>
                <div className="services__content">
                  <h3 className="services__title"><Link to="/about">{item.title}</Link></h3>
                  <p>{item.subtitle}</p>

                  <Link to="/about" className="link-btn-2">
                    <i className="far fa-arrow-right"></i>
                    <i className="far fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;