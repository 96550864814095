import { Duration, Enrolled, Instructor, Language, Lectures, Video } from "../svg";

export const course_data = [
  {
    id: 1,
    courseImage: '/assets/img/course/course-1.jpg',
    trending_1:true,
    featured:true,
    lesson: 43,
    language:'English',
    skill_label:'Beginner',
    title: 'Genetic Engineering.',
    rating: 4.5,
    y_video_id:'ojwqDTX6710',
    teacherImg: '/assets/img/course/teacher/teacher-1.jpg',
    course_summary:'What is genomics? Genomics is the field that studies the entire set of an organism’s DNA (the genome), including all its genes, interactions of those genes with each other and as well as with the persons environment.',
    teacherName: 'Clevaforce',
    category: 'Molecular Biology',
    price: 'free',
    last_update: 'March 24, 2022',
    total_rating:14,
    overview: "Genomics is the field that studies the entire set of an organism’s DNA (the genome), including all its genes, interactions of those genes with each other and as well as with the persons environment.",
    tag: ['Genomics', 'Molecular Biology', 'Molecular Tools'],
    target_audience: [" Students, Business Professionals", " Downloadable lectures,"],
    other_instructor: [
      {
        teacher_img: "/assets/img/course/teacher/teacher-3.jpg",
        teacher_name: "Eleanor Fant",
        teacher_title: "Genetic Engineer"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-2.jpg",
        teacher_name: "Lauren Stamps",
        teacher_title: "Genetic Engineer"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-1.jpg",
        teacher_name: "Jonquil Von",
        teacher_title: "Associate"
      },
    ],
    curriculum: [
      {
        id: 'course__accordion',
        course_c_title: 'Week 01',
        course_info: [
          {
            course_c_icon: <Video />,
            course_c_text: 'Genomics',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Epigenomics',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Genetic Engineering',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Genetic Engineering Techniques',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Genetic Engineering Steps',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Emerging Genetic Engineering Technologies',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Applications of Genetic Engineering',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
        ]
      },
      
    ],
    reviews:{
      text:"The videos are really high quality, this is super awesome, make more courses",
      comments:[
        {
          user:'/assets/img/course/comment/course-comment-1.jpg',
          name:'Eleanor Fant',
          date:'July 14, 2022',
          comment:"Best course ever, thanks for the simplicity",
          rating:5,
        },
        {
          user:'/assets/img/course/comment/course-comment-2.jpg',
          name:'Shahnewaz Sakil',
          date:'July 17, 2022',
          comment:"This is really good, keep it coming",
          rating:4,
        },
      ]
    },
    members:[
      {
        img:'/assets/img/course/instructor/course-instructor-1.jpg',
        name:'Shahnewaz Sakil',
        title:'Engineer',
        member_info:[
          {
            title:7,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:5,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:3.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-2.jpg',
        name:'Lauren Stamps',
        title:'Teacher',
        member_info:[
          {
            title:8,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:6,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:5.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-3.jpg',
        name:'Jonquil Von',
        title:'Associate',
        member_info:[
          {
            title:9,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:15,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:4.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
    ],
    course_content:[
      {
        icon:<Instructor/>,
        title:'Instructor :',
        subtitle:'Clevaforce'
      },
      {
        icon:<Lectures/>,
        title:'Mini-Course :',
        subtitle:14,
      },
      {
        icon:<Duration/>,
        title:'Duration :',
        subtitle:'Self-paced',
      },
      {
        icon:<Enrolled/>,
        title:'Enrolled :',
        subtitle:'20 students',
      },
      {
        icon:<Language/>,
        title:'Language :',
        subtitle:'English',
      },
    ]
  },
  {
    id: 2,
    courseImage: '/assets/img/course/course-2.jpg',
    popularity_1:true,
    trending:true,
    lesson: '72',
    language:'English',
    skill_label:'Intermediate',
    title: 'Cell Therapy',
    rating: '4.0',
    y_video_id:'Y1Ap0MKVAlo',
    teacherImg: '/assets/img/course/teacher/teacher-2.jpg',
    course_summary:'Cellular therapy generally aims to to replace or repair damaged tissue and/or cells by transplantation of human cells.',
    teacherName: 'Clevaforce',
    category: 'Drug Discovery',
    price: 32.00,
    oldPrice: 68.00,
    color: 'sky-blue',
    last_update: 'July 15, 2022',
    total_rating:16,
    overview: "Cellular therapy generally aims to to replace or repair damaged tissue and/or cells by transplantation of human cells.",
    tag: ['Drug Discovery', 'Pharma Therapy', 'Drug Development'],
    target_audience: [" Business's managers, leaders", " Downloadable lectures, code and design assets for all projects", "Anyone who is finding a chance to get the promotion"],
    other_instructor: [
      {
        teacher_img: "/assets/img/course/teacher/teacher-3.jpg",
        teacher_name: "Eleanor Fant",
        teacher_title: "Instructor"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-2.jpg",
        teacher_name: "Lauren Stamps",
        teacher_title: "Teacher"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-1.jpg",
        teacher_name: "Jonquil Von",
        teacher_title: "Associate"
      },
    ],
    curriculum: [
      {
        id: 'course__accordion',
        course_c_title: 'Cell Therapy Short Course',
        course_info: [
          
          {
            course_c_icon: <Video />,
            course_c_text: 'Introduction to Cell Therapy',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Cells Involved in Cell Therapy',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Types of Cell Therapy',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Autologous vs Allogeneic Cell Therapy',
            clock_icon: 'icon_clock_alt',
            minute: '4 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Overview of Stem Cells',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Multicellular Versus Unicellular Therapies',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },          
          {
            course_c_icon: <Video />,
            course_c_text: 'Applications of Cell Therapy',
            clock_icon: 'icon_clock_alt',
            minute: '1 minutes',
          },
        ]
      },
      // {
      //   id: 'course__accordion-2',
      //   course_c_title: 'Week 02',
      //   course_info: [
      //     {
      //       course_c_icon: <Reading />,
      //       course_c_text: 'Reading:',
      //       course_c_text_2: 'Ut enim ad minim veniam',
      //       clock_icon: 'icon_clock_alt',
      //       minute: '14 minutes',
      //       questions: '2 questions'
      //     },
      //     {
      //       course_c_icon: <Video />,
      //       course_c_text: 'Video:',
      //       course_c_text_2: 'Greetings and introduction',
      //       clock_icon: 'icon_clock_alt',
      //       minute: '14 minutes',
      //     },
      //     {
      //       course_c_icon: <Audio />,
      //       course_c_text: 'Audio:',
      //       course_c_text_2: 'Interactive lesson',
      //       clock_icon: 'icon_clock_alt',
      //       minute: '15 minutes',
      //       questions: '3 questions'
      //     },
      //     {
      //       course_c_icon: <Reading />,
      //       course_c_text: 'Reading:',
      //       course_c_text_2: 'Ut enim ad minim veniam',
      //       clock_icon: 'icon_clock_alt',
      //       minute: '22 minutes',
      //     },
      //   ]
      // },
    ],
    reviews:{
      text:"Gosh william I'm telling crikey burke I don't want no agro A bit of how's your father bugger all mate off his nut that, what a plonker cuppa owt to do",
      comments:[
        {
          user:'/assets/img/course/comment/course-comment-1.jpg',
          name:'Eleanor Fant',
          date:'July 14, 2022',
          comment:"So I said lurgy dropped a clanger Jeffrey bugger cuppa gosh David blatant have it, standard A bit of how's your father my lady absolutely.",
          rating:5,
        },
        {
          user:'/assets/img/course/comment/course-comment-2.jpg',
          name:'Shahnewaz Sakil',
          date:'July 17, 2022',
          comment:"David blatant have it, standard A bit of how's your father my lady absolutely.",
          rating:4,
        },
      ]
    },
    members:[
      {
        img:'/assets/img/course/instructor/course-instructor-1.jpg',
        name:'Shahnewaz Sakil',
        title:'Engineer',
        member_info:[
          {
            title:7,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:5,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:3.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-2.jpg',
        name:'Lauren Stamps',
        title:'Teacher',
        member_info:[
          {
            title:8,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:6,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:5.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-3.jpg',
        name:'Jonquil Von',
        title:'Associate',
        member_info:[
          {
            title:9,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:15,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:4.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
    ],
    course_content:[
      {
        icon:<Instructor/>,
        title:'Instructor :',
        subtitle:'Clevaforce'
      },
      {
        icon:<Lectures/>,
        title:'Mini-Course :',
        subtitle:14,
      },
      {
        icon:<Duration/>,
        title:'Duration :',
        subtitle:'Self-paced',
      },
      {
        icon:<Enrolled/>,
        title:'Enrolled :',
        subtitle:'20 students',
      },
      {
        icon:<Language/>,
        title:'Language :',
        subtitle:'English',
      },
    ]
  },
  {
    id: 3,
    courseImage: '/assets/img/course/course-3.jpg',
    trending_1:true,
    featured:true,
    lesson: '35',
    language:'English',
    skill_label:'Beginner',
    title: 'Drug Discovery and Development',
    rating: 4.3,
    y_video_id:'tCcjD5u-rds',
    teacherImg: '/assets/img/course/teacher/teacher-3.jpg',
    course_summary:'This video is an overview of the drug discovery and drug development.',
    teacherName: 'Clevaforce',
    category: 'Drug Discovery',
    price: 13.00,
    oldPrice: 19.00,
    color: 'green',
    last_update: 'Jun 7, 2022',
    total_rating:17,
    overview: "Drug Discovery is the series of activities involved in identifying a novel drug candidate for therapeutic purposes, while Drug Development comprises all the activities involved in transforming a drug candidate (the end-product of the discovery phase) to a product approved for marketing by the appropriate regulatory authorities. ",
    tag: ['Drug Discovery', 'Drug Development', 'Biopharma'],
    target_audience: [" Students", " Professionals", "Anyone who wants to learn about drug discovery"],
    other_instructor: [
      {
        teacher_img: "/assets/img/course/teacher/teacher-3.jpg",
        teacher_name: "Eleanor Fant",
        teacher_title: "Instructor"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-2.jpg",
        teacher_name: "Lauren Stamps",
        teacher_title: "Teacher"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-1.jpg",
        teacher_name: "Jonquil Von",
        teacher_title: "Associate"
      },
    ],
    curriculum: [
      {
        id: 'course__accordion',
        course_c_title: 'Week 01',
        course_info: [
          {
            course_c_icon: <Video />,
            course_c_text: 'Drug Discovery and Development',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Drug Discovery',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Preclinical Development',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Clinical Development',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Phases of Clinical Drug Development',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          
        ]
      },
      
    ],
    reviews:{
      text:"The videos are really high quality, this is super awesome, make more courses",
      comments:[
        {
          user:'/assets/img/course/comment/course-comment-1.jpg',
          name:'Eleanor Fant',
          date:'July 14, 2022',
          comment:"Best course ever, thanks for the simplicity",
          rating:5,
        },
        {
          user:'/assets/img/course/comment/course-comment-2.jpg',
          name:'Shahnewaz Sakil',
          date:'July 17, 2022',
          comment:"This is really good, keep it coming",
          rating:4,
        },
      ]
    },
    members:[
      {
        img:'/assets/img/course/instructor/course-instructor-1.jpg',
        name:'Shahnewaz Sakil',
        title:'Engineer',
        member_info:[
          {
            title:7,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:5,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:3.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-2.jpg',
        name:'Lauren Stamps',
        title:'Teacher',
        member_info:[
          {
            title:8,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:6,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:5.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-3.jpg',
        name:'Jonquil Von',
        title:'Associate',
        member_info:[
          {
            title:9,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:15,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:4.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
    ],
    course_content:[
      {
        icon:<Instructor/>,
        title:'Instructor :',
        subtitle:'Clevaforce'
      },
      {
        icon:<Lectures/>,
        title:'Mini-Course :',
        subtitle:14,
      },
      {
        icon:<Duration/>,
        title:'Duration :',
        subtitle:'Self-paced',
      },
      {
        icon:<Enrolled/>,
        title:'Enrolled :',
        subtitle:'20 students',
      },
      {
        icon:<Language/>,
        title:'Language :',
        subtitle:'English',
      },
    ]
  },
  {
    id: 4,
    courseImage: '/assets/img/course/course-4.jpg',
    featured_1:true,
    featured:true,
    lesson: '60',
    language:'English',
    skill_label:'Beginner',
    title: 'Green Chemistry',
    rating: 3.5,
    y_video_id:'8NReR4tLUeM',
    teacherImg: '/assets/img/course/teacher/teacher-4.jpg',
    course_summary:'Green Chemistry is the design of chemical products to reduce the generation of hazardous compounds',
    teacherName: 'Clevaforce',
    category: 'Chemistry',
    price: 12.00,
    oldPrice: 24.00,
    color: 'blue',
    last_update: 'May 24, 2022',
    total_rating:20,
    overview: "Green chemistry aka sustainable chemistry is the design of chemical products and processes that reduce or eliminate the use or generation of hazardous substances. Green chemistry applies across the life cycle of a chemical product, including its design, manufacture, use, and ultimate disposal.",
    tag: ['Chemistry', 'Sustainability', 'Green Chemistry'],
    target_audience: [" Students", " Professionals", "Anyone who wants to learn"],
    other_instructor: [
      {
        teacher_img: "/assets/img/course/teacher/teacher-3.jpg",
        teacher_name: "Eleanor Fant",
        teacher_title: "Instructor"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-2.jpg",
        teacher_name: "Lauren Stamps",
        teacher_title: "Teacher"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-1.jpg",
        teacher_name: "Jonquil Von",
        teacher_title: "Associate"
      },
    ],
    curriculum: [
      {
        id: 'course__accordion',
        course_c_title: 'Green Chemistry',
        course_info: [
          {
            course_c_icon: <Video />,
            course_c_text: 'Introduction to Green Chemistry',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: '12 Principles of Green Chemistry',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          
        ]
      },
      
    ],
    reviews:{
      text:"The videos are really high quality, this is super awesome, make more courses",
      comments:[
        {
          user:'/assets/img/course/comment/course-comment-1.jpg',
          name:'Eleanor Fant',
          date:'July 14, 2022',
          comment:"Best course ever, thanks for the simplicity",
          rating:5,
        },
        {
          user:'/assets/img/course/comment/course-comment-2.jpg',
          name:'Shahnewaz Sakil',
          date:'July 17, 2022',
          comment:"This is really good, keep it coming",
          rating:4,
        },
      ]
    },
    members:[
      {
        img:'/assets/img/course/instructor/course-instructor-1.jpg',
        name:'Shahnewaz Sakil',
        title:'Engineer',
        member_info:[
          {
            title:7,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:5,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:3.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-2.jpg',
        name:'Lauren Stamps',
        title:'Teacher',
        member_info:[
          {
            title:8,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:6,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:5.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-3.jpg',
        name:'Jonquil Von',
        title:'Associate',
        member_info:[
          {
            title:9,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:15,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:4.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
    ],
    course_content:[
      {
        icon:<Instructor/>,
        title:'Instructor :',
        subtitle:'Clevaforce'
      },
      {
        icon:<Lectures/>,
        title:'Mini-Course :',
        subtitle:14,
      },
      {
        icon:<Duration/>,
        title:'Duration :',
        subtitle:'Self-paced',
      },
      {
        icon:<Enrolled/>,
        title:'Enrolled :',
        subtitle:'20 students',
      },
      {
        icon:<Language/>,
        title:'Language :',
        subtitle:'English',
      },
    ]
  },
  {
    id: 5,
    courseImage: '/assets/img/course/course-5.jpg',
    art_design_1:true,
    lesson: '28',
    language:'English',
    skill_label:'Intermediate',
    title: 'Targeted Protein Degraders',
    rating: 4.5,
    y_video_id:'woc1pUTfq5o',
    teacherImg: '/assets/img/course/teacher/teacher-5.jpg',
    course_summary:'Protein degraders are leverage the ubiquitin-proteasome system (UPS) to change the destiny of disease-causing proteins',
    teacherName: 'Clevaforce',
    category: 'Proteomics',
    price: 25.00,
    oldPrice: 36.00,
    color: 'orange',
    last_update: 'February 24, 2022',
    total_rating:22,
    overview: "Protein degraders are leverage the ubiquitin-proteasome system (UPS) to change the destiny of disease-causing proteins. Usually small molecules with two active ends, one that binds to the protein of interest (POI) and the other that binds to a protein called E3 ubiquitin ligase. These bifunctional molecules force a handshake between the ubiquitin and the protein, sending it to the trash. ",
    tag: ['Proteomics', 'Molecular Biology', 'Protein Degraders'],
    target_audience: [" Students", " Professionals", "Anyone willing to learn"],
    other_instructor: [
      {
        teacher_img: "/assets/img/course/teacher/teacher-3.jpg",
        teacher_name: "Eleanor Fant",
        teacher_title: "Instructor"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-2.jpg",
        teacher_name: "Lauren Stamps",
        teacher_title: "Teacher"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-1.jpg",
        teacher_name: "Jonquil Von",
        teacher_title: "Associate"
      },
    ],
    curriculum: [
      {
        id: 'course__accordion',
        course_c_title: 'Protein Degraders',
        course_info: [
          {
            course_c_icon: <Video />,
            course_c_text: 'Introduction to Protein Degraders',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Targeted Protein Degraders',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Proteolysis Targeting Chimeras (PROTACs)',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Molecular Glues',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'The Ubiquitin-Proteasome System (UPS)',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Applications and Limitations of Targeted Protein Degraders',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
        ]
      },
      
    ],
    reviews:{
      text:"The videos are really high quality, this is super awesome, make more courses",
      comments:[
        {
          user:'/assets/img/course/comment/course-comment-1.jpg',
          name:'Eleanor Fant',
          date:'July 14, 2022',
          comment:"Best course ever, thanks for the simplicity",
          rating:5,
        },
        {
          user:'/assets/img/course/comment/course-comment-2.jpg',
          name:'Shahnewaz Sakil',
          date:'July 17, 2022',
          comment:"This is really good, keep it coming",
          rating:4,
        },
      ]
    },
    members:[
      {
        img:'/assets/img/course/instructor/course-instructor-1.jpg',
        name:'Shahnewaz Sakil',
        title:'Engineer',
        member_info:[
          {
            title:7,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:5,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:3.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-2.jpg',
        name:'Lauren Stamps',
        title:'Teacher',
        member_info:[
          {
            title:8,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:6,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:5.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-3.jpg',
        name:'Jonquil Von',
        title:'Associate',
        member_info:[
          {
            title:9,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:15,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:4.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
    ],
    course_content:[
      {
        icon:<Instructor/>,
        title:'Instructor :',
        subtitle:'Clevaforce'
      },
      {
        icon:<Lectures/>,
        title:'Mini-Course :',
        subtitle:14,
      },
      {
        icon:<Duration/>,
        title:'Duration :',
        subtitle:'Self-paced',
      },
      {
        icon:<Enrolled/>,
        title:'Enrolled :',
        subtitle:'20 students',
      },
      {
        icon:<Language/>,
        title:'Language :',
        subtitle:'English',
      },
    ]
  },
  {
    id: 6,
    courseImage: '/assets/img/course/course-6.jpg',
    featured_1:true,
    lesson: '38',
    language:'English',
    skill_label:'Expert',
    title: 'Liquid Biopsy',
    rating: 4.8,
    y_video_id:'vURtJNEy1-M',
    teacherImg: '/assets/img/course/teacher/teacher-6.jpg',
    course_summary:'Liquid biopsy is the analysis of non-solid tissues, usually through bioinformatics aid',
    teacherName: 'Clevaforce',
    category: 'Bioinformatics',
    price: 35.00,
    oldPrice: 46.00,
    color: 'pink',
    last_update: 'March 20, 2022',
    total_rating:25,
    overview: "Liquid biopsy refers to the analysis of non-solid tissue; including blood and other body fluids. It comprises a set of analytes including circulating tumor cells (CTCs), circulating free DNA (cfDNA), RNA and others. ",
    tag: ['Liquid biopsy', 'Molecular diagnostics', 'Bioinformatics'],
    target_audience: [" Students", " Professionals", "Anyone willing to learn"],
    other_instructor: [
      {
        teacher_img: "/assets/img/course/teacher/teacher-3.jpg",
        teacher_name: "Eleanor Fant",
        teacher_title: "Instructor"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-2.jpg",
        teacher_name: "Lauren Stamps",
        teacher_title: "Teacher"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-1.jpg",
        teacher_name: "Jonquil Von",
        teacher_title: "Associate"
      },
    ],
    curriculum: [
      {
        id: 'course__accordion',
        course_c_title: 'Week 01',
        course_info: [
          
          {
            course_c_icon: <Video />,
            course_c_text: 'Introduction to Liquid Biopsy',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Liquid Biopsy Sample Collection Method',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Liquid Biopsy Analytes',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Liquid Biopsy vs Tissue Biopsy',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Methods for Liquid Biopsy Analysis',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Liquid Biopsy Applications',
            clock_icon: 'icon_clock_alt',
            minute: '4 minutes',
          },
        ]
      },
      
    ],
    reviews:{
      text:"The videos are really high quality, this is super awesome, make more courses",
      comments:[
        {
          user:'/assets/img/course/comment/course-comment-1.jpg',
          name:'Eleanor Fant',
          date:'July 14, 2022',
          comment:"Best course ever, thanks for the simplicity",
          rating:5,
        },
        {
          user:'/assets/img/course/comment/course-comment-2.jpg',
          name:'Shahnewaz Sakil',
          date:'July 17, 2022',
          comment:"This is really good, keep it coming",
          rating:4,
        },
      ]
    },
    members:[
      {
        img:'/assets/img/course/instructor/course-instructor-1.jpg',
        name:'Shahnewaz Sakil',
        title:'Engineer',
        member_info:[
          {
            title:7,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:5,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:3.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-2.jpg',
        name:'Lauren Stamps',
        title:'Teacher',
        member_info:[
          {
            title:8,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:6,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:5.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-3.jpg',
        name:'Jonquil Von',
        title:'Associate',
        member_info:[
          {
            title:9,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:15,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:4.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
    ],
    course_content:[
      {
        icon:<Instructor/>,
        title:'Instructor :',
        subtitle:'Clevaforce'
      },
      {
        icon:<Lectures/>,
        title:'Mini-Course :',
        subtitle:14,
      },
      {
        icon:<Duration/>,
        title:'Duration :',
        subtitle:'Self-paced',
      },
      {
        icon:<Enrolled/>,
        title:'Enrolled :',
        subtitle:'20 students',
      },
      {
        icon:<Language/>,
        title:'Language :',
        subtitle:'English',
      },
    ]
  },
  {
    id: 7,
    courseImage: '/assets/img/course/course-7.jpg',
    lesson: '26',
    language:'English',
    skill_label:'Beginner',
    title: 'Regulatory (FDA)Terms in Drug Development',
    rating: 4.6,
    y_video_id:'EUlpAuiMN_U',
    teacherImg: '/assets/img/course/teacher/teacher-7.jpg',
    course_summary:'This course provides an overview of common regulatory terms in drug development',
    teacherName: 'Clevaforce',
    category: 'Regulatory',
    price: 16.00,
    oldPrice: 32.00,
    color: 'orange',
    last_update: 'November 24, 2022',
    total_rating:29,
    overview: "The subdivisions of the drug development process into stages is based on the regulatory milestones during drug development, and there are various terminologies used by regulatory agencies. This mini-course focus on FDA terminologies.",
    tag: ['Drug Development', 'FDA', 'Regulatory'],
    target_audience: [" Students", " Professionals", "Anyone willing to learn"],
    other_instructor: [
      {
        teacher_img: "/assets/img/course/teacher/teacher-3.jpg",
        teacher_name: "Eleanor Fant",
        teacher_title: "Instructor"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-2.jpg",
        teacher_name: "Lauren Stamps",
        teacher_title: "Teacher"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-1.jpg",
        teacher_name: "Jonquil Von",
        teacher_title: "Associate"
      },
    ],
    curriculum: [
      {
        id: 'course__accordion',
        course_c_title: 'Regulatory Terms in Drug Development',
        course_info: [
          {
            course_c_icon: <Video />,
            course_c_text: 'FDA Regulatory Milestones',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Regulatory Submissions and Applications',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'FDA Expidited Programs',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Useful Regulatory Terminologies in Drug Development',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'FDA Meeting Types',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Formal FDA Meetings',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'FDA Communication Types',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
        ]
      },
      
    ],
    reviews:{
      text:"The videos are really high quality, this is super awesome, make more courses",
      comments:[
        {
          user:'/assets/img/course/comment/course-comment-1.jpg',
          name:'Eleanor Fant',
          date:'July 14, 2022',
          comment:"Best course ever, thanks for the simplicity",
          rating:5,
        },
        {
          user:'/assets/img/course/comment/course-comment-2.jpg',
          name:'Shahnewaz Sakil',
          date:'July 17, 2022',
          comment:"This is really good, keep it coming",
          rating:4,
        },
      ]
    },
    members:[
      {
        img:'/assets/img/course/instructor/course-instructor-1.jpg',
        name:'Shahnewaz Sakil',
        title:'Engineer',
        member_info:[
          {
            title:7,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:5,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:3.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-2.jpg',
        name:'Lauren Stamps',
        title:'Teacher',
        member_info:[
          {
            title:8,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:6,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:5.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-3.jpg',
        name:'Jonquil Von',
        title:'Associate',
        member_info:[
          {
            title:9,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:15,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:4.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
    ],
    course_content:[
      {
        icon:<Instructor/>,
        title:'Instructor :',
        subtitle:'Clevaforce'
      },
      {
        icon:<Lectures/>,
        title:'Mini-Course :',
        subtitle:14,
      },
      {
        icon:<Duration/>,
        title:'Duration :',
        subtitle:'Self-paced',
      },
      {
        icon:<Enrolled/>,
        title:'Enrolled :',
        subtitle:'20 students',
      },
      {
        icon:<Language/>,
        title:'Language :',
        subtitle:'English',
      },
    ]
  },
  {
    id: 8,
    courseImage: '/assets/img/course/course-8.jpg',
    trending:true,
    lesson: '13',
    language:'English',
    skill_label:'Intermediate',
    title: 'Contract Research Organizations',
    rating: 4.4,
    y_video_id:'VebQYWcVHTw',
    teacherImg: '/assets/img/course/teacher/teacher-8.jpg',
    course_summary:'This course provides an overview of contract research organizations',
    teacherName: 'Clevaforce',
    category: 'Drug Discovery',
    price: 12.00,
    oldPrice: 24.00,
    color: 'pink',
    last_update: 'December 24, 2022',
    total_rating:32,
    overview: "Contract Research Organizations, also known as CROs, are companies that provide a wide range of research and development services on contract basis to clients in the pharmaceutical, biotechnology and medical device industries. ",
    tag: ['Drug Dicovery', 'CROs', 'Services'],
    target_audience: [" Students", " Professionals", "Anyone willing to learn"],
    other_instructor: [
      {
        teacher_img: "/assets/img/course/teacher/teacher-3.jpg",
        teacher_name: "Eleanor Fant",
        teacher_title: "Instructor"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-2.jpg",
        teacher_name: "Lauren Stamps",
        teacher_title: "Teacher"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-1.jpg",
        teacher_name: "Jonquil Von",
        teacher_title: "Associate"
      },
    ],
    curriculum: [
      {
        id: 'course__accordion',
        course_c_title: 'Contract Research Organizations',
        course_info: [
          {
            course_c_icon: <Video />,
            course_c_text: 'Overview of Contract Research Organizations',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Evolution of CROs',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Preclinical CROs',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Clinical CROs',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Why CROs Exist',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
        ]
      },
      
    ],
    reviews:{
      text:"The videos are really high quality, this is super awesome, make more courses",
      comments:[
        {
          user:'/assets/img/course/comment/course-comment-1.jpg',
          name:'Eleanor Fant',
          date:'July 14, 2022',
          comment:"Best course ever, thanks for the simplicity",
          rating:5,
        },
        {
          user:'/assets/img/course/comment/course-comment-2.jpg',
          name:'Shahnewaz Sakil',
          date:'July 17, 2022',
          comment:"This is really good, keep it coming",
          rating:4,
        },
      ]
    },
    members:[
      {
        img:'/assets/img/course/instructor/course-instructor-1.jpg',
        name:'Shahnewaz Sakil',
        title:'Engineer',
        member_info:[
          {
            title:7,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:5,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:3.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-2.jpg',
        name:'Lauren Stamps',
        title:'Teacher',
        member_info:[
          {
            title:8,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:6,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:5.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-3.jpg',
        name:'Jonquil Von',
        title:'Associate',
        member_info:[
          {
            title:9,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:15,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:4.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
    ],
    course_content:[
      {
        icon:<Instructor/>,
        title:'Instructor :',
        subtitle:'Clevaforce'
      },
      {
        icon:<Lectures/>,
        title:'Mini-Course :',
        subtitle:14,
      },
      {
        icon:<Duration/>,
        title:'Duration :',
        subtitle:'Self-paced',
      },
      {
        icon:<Enrolled/>,
        title:'Enrolled :',
        subtitle:'20 students',
      },
      {
        icon:<Language/>,
        title:'Language :',
        subtitle:'English',
      },
    ]
  },
  {
    id: 9,
    courseImage: '/assets/img/course/course-9.jpg',
    lesson: '25',
    language:'English',
    skill_label:'Beginner',
    title: 'Contract Development & Manufacturing Services',
    rating: 4.2,
    y_video_id:'qNB4x-zwg9w',
    teacherImg: '/assets/img/course/teacher/teacher-9.jpg',
    course_summary:'CDMOs are contract development and manufacturing organizations, they usually offer development and manufacturing services in both preclinical and clinical stages.',
    teacherName: 'Clevaforce',
    category: 'Drug Development',
    price: 15.00,
    oldPrice: 45.00,
    color: 'blue-2',
    last_update: 'July 10, 2022',
    total_rating:34,
    overview: "Pharmaceutical development involves key activities that result in advancing a potentially new drug candidate into clinical practice. CDMOs usually offer these services in both preclinical and clinical stages.",
    tag: ['CDMOs', 'Drug Development', 'Pharmaceutical Development'],
    target_audience: [" Students", " Professionals", "Anyone willing to learn"],
    other_instructor: [
      {
        teacher_img: "/assets/img/course/teacher/teacher-3.jpg",
        teacher_name: "Eleanor Fant",
        teacher_title: "Instructor"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-2.jpg",
        teacher_name: "Lauren Stamps",
        teacher_title: "Teacher"
      },
      {
        teacher_img: "/assets/img/course/teacher/teacher-1.jpg",
        teacher_name: "Jonquil Von",
        teacher_title: "Associate"
      },
    ],
    curriculum: [
      {
        id: 'course__accordion',
        course_c_title: 'CDMOs',
        course_info: [
          {
            course_c_icon: <Video />,
            course_c_text: 'Pharmaceutical Development',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Overview of Pharma CDMOs',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Analytical Method Development and Validation',
            clock_icon: 'icon_clock_alt',
            minute: '2 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Pharmaceutical Manufacturing Services',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Good Manufacturing Practice (GMP)',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'CDMOs, CROs, CMOs, and CRDMOs',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Drug Substance, Drug Products and Excipients',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Preformulation Studies',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
          {
            course_c_icon: <Video />,
            course_c_text: 'Formulation Development',
            clock_icon: 'icon_clock_alt',
            minute: '3 minutes',
          },
        ]
      },
      
    ],
    reviews:{
      text:"The videos are really high quality, this is super awesome, make more courses",
      comments:[
        {
          user:'/assets/img/course/comment/course-comment-1.jpg',
          name:'Eleanor Fant',
          date:'July 14, 2022',
          comment:"Best course ever, thanks for the simplicity",
          rating:5,
        },
        {
          user:'/assets/img/course/comment/course-comment-2.jpg',
          name:'Shahnewaz Sakil',
          date:'July 17, 2022',
          comment:"This is really good, keep it coming",
          rating:4,
        },
      ]
    },
    members:[
      {
        img:'/assets/img/course/instructor/course-instructor-1.jpg',
        name:'Shahnewaz Sakil',
        title:'Engineer',
        member_info:[
          {
            title:7,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:5,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:3.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-2.jpg',
        name:'Lauren Stamps',
        title:'Teacher',
        member_info:[
          {
            title:8,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:6,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:5.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
      {
        img:'/assets/img/course/instructor/course-instructor-3.jpg',
        name:'Jonquil Von',
        title:'Associate',
        member_info:[
          {
            title:9,
            text:'Courses',
            pl:'pl-45'
          },
          {
            title:15,
            text:'Review',
            pl:'pl-70'
          },
          {
            title:4.00,
            text:'Rating',
            pl:'pl-85'
          },
        ]
      },
    ],
    course_content:[
      {
        icon:<Instructor/>,
        title:'Instructor :',
        subtitle:'Clevaforce'
      },
      {
        icon:<Lectures/>,
        title:'Mini-Course :',
        subtitle:14,
      },
      {
        icon:<Duration/>,
        title:'Duration :',
        subtitle:'Self-paced',
      },
      {
        icon:<Enrolled/>,
        title:'Enrolled :',
        subtitle:'20 students',
      },
      {
        icon:<Language/>,
        title:'Language :',
        subtitle:'English',
      },
    ]
  },


]