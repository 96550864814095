export const testimonial_1 = [
  {
    id:1,
    img:'/assets/img/testimonial/testi-1.jpg',
    content:'“ Barmy loo sloshed porkiesdo with me down the pub say bubble and squeak. ”',
    name:'Jason Response',
    title:'Discovery Scientist'
  },
  {
    id:2,
    img:'/assets/img/testimonial/testi-1.jpg',
    content:'“ Barmy loo sloshed porkiesdo with me down the pub say bubble and squeak. ”',
    name:'Naim Ahmed',
    title:'Web developer'
  },
]


export const testimonial_2 = [
  {
    id:1,
    desc:"“After I started learning design with Quillow, I realized that I had improved to very advanced levels. While I am studying at my university, I design as an additional income and I am sure that I will do this professionally.”",
    name:'James Lee,',
    subtitle:'Student @Clevaforce'
  },
  {
    id:2,
    desc:"“After I started learning design with Quillow, I realized that I had improved to very advanced levels. While I am studying at my university, I design as an additional income and I am sure that I will do this professionally.”",
    name:'James Lee,',
    subtitle:'Student @Clevaforce'
  },
  {
    id:3,
    desc:"“After I started learning design with Quillow, I realized that I had improved to very advanced levels. While I am studying at my university, I design as an additional income and I am sure that I will do this professionally.”",
    name:'James Lee,',
    subtitle:'Student @Clevaforce'
  },
  {
    id:4,
    desc:"“After I started learning design with Quillow, I realized that I had improved to very advanced levels. While I am studying at my university, I design as an additional income and I am sure that I will do this professionally.”",
    name:'James Lee,',
    subtitle:'Student @Clevaforce'
  },
]

export const testimonial_2_nav = [
  {
    img:'/assets/img/testimonial/home-3/testi-1.jpg',
  },
  {
    img:'/assets/img/testimonial/home-3/testi-2.jpg',
  },
  {
    img:'/assets/img/testimonial/home-3/testi-3.jpg',
  },
  {
    img:'/assets/img/testimonial/home-3/testi-2.jpg',
  }
]